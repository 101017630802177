<template>
  <div class="progress-bar">
    <h1>스낵고 학생 유형 테스트</h1>
    <img class="prev" src="/image/quiz/prev.png" alt="뒤로가기" @click="() => {$emit('prev')}" />
    <div class="index" :style="{left: `${quizIndex / maxQuizLength * 100}%`}">
      <img src="/image/quiz/ball.png" />
      <span>{{quizIndex + 1}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    quizIndex: {
      type: Number,
      default() {
        return 0;
      }
    },
    maxQuizLength: {
      type: Number,
      default() {
        return 12;
      }
    }
  },
  methods: {
    goHome() {
      this.$router.push({
        name: "Home"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  height: 30px;
  max-width: 431px;
  display: flex;
  margin: 43px auto 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/image/quiz/bar.png");
  h1 {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    left: 50%;
    opacity: 0.5;
    transform: translate(-50%, -35px);
  }
  & > .prev {
    position: absolute;
    width: 28px;
    transform: translate(0, -35px);
    cursor: pointer;
  }
  & > .index {
    display: flex;
    position: relative;
    transition: 0.5s ease-in-out;
    justify-content: center;
    align-items: center;
    & > img {
      width: 32px;
    }
    & > span {
      position: absolute;
      font-weight: 800;
      transform: translate(-1px, -1px);
    }
  }
}
</style>