<template>
  <transition name="fade">
    <!-- 퀴즈 페이지 -->
    <div v-if="show && !showLoading" class="container test">
      <Progress :quizIndex="quizIndex" :maxQuizLength="quiz.length" @prev="prevQuiz" />
      <transition-group name="slide" tag="div" class="div-slider" v-for="(q, i) in quiz" :key="i">
        <header v-if="i === quizIndex && quizTransition" :key="`header-${i}`" class="quiz-title">
          <img
            :src="`/image/quiz/${quizIndex +1}-아이콘.png`"
            class="icon"
            :style="{transform: `translate(${q.icon.x}px, ${q.icon.y}px)`}"
          />
          <img :src="`/image/quiz/${quizIndex +1}-질문지.png`" :alt="q.q" class="title" />
        </header>
        <div
          v-if="i === quizIndex && quizTransition"
          :class="['cover', quizIndex === 4 || quizIndex === 7 ? 'pulled-up' : '']"
          :key="`cover-${i}`"
        >
          <button
            v-for="(a, i) in q.a"
            class="quiz"
            :key="`answer-${i}`"
            v-html="a.txt"
            @click="getScore(a.type)"
          ></button>
        </div>
      </transition-group>
    </div>
    <!-- 로딩창 -->
    <div v-if="showLoading" class="container test loading">
      <div
        v-for="(letters, i) in loadingText"
        :key="`letter-${i}`"
        class="each-text"
        :style="{transform: `translate(${letters.x}px, ${letters.y}px)`}"
      >
        <img
          v-for="(l, num) in letters.images"
          :src="`/image/loading/${letters.txt}${num + 1}.png`"
          :key="`${num + 1}-${letters.txt}`"
          :style="{transform: `translate(${l.x}px, ${l.y}px)`, 'animation-delay': `${l.delay * 60}ms`}"
        />
      </div>
    </div>
  </transition>
</template>
<script>
import Progress from "@/components/Progress";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Test",
  components: {
    Progress
  },
  data() {
    return {
      show: false,
      showLoading: false,
      quizIndex: 0,
      lastType: [],
      quizTransition: true,
      loadingText: [
        {
          txt: "생",
          x: -30,
          y: 0,
          images: [
            { x: 0, y: 0, delay: 0 },
            { x: -7, y: -1, delay: 1 },
            { x: -8, y: 0, delay: 2 },
            { x: -13, y: -17, delay: 3 },
            { x: -23, y: -2, delay: 4 },
            { x: -44, y: 22, delay: 5 },
            { x: -51, y: 17, delay: 6 }
          ]
        },
        {
          txt: "기",
          x: -25,
          y: -23,
          images: [
            { x: 0, y: 0, delay: 7 },
            { x: 9, y: 0, delay: 8 }
          ]
        },
        {
          txt: "부",
          x: 43,
          y: -63,
          images: [
            { x: 0, y: 0, delay: 9 },
            { x: -5, y: -15, delay: 10 },
            { x: -15, y: -8, delay: 11 },
            { x: -43, y: 22, delay: 12 },
            { x: -49, y: 7, delay: 13 }
          ]
        },
        {
          txt: "가",
          x: 55,
          y: -86,
          images: [
            { x: 0, y: 0, delay: 14 },
            { x: 7, y: 0, delay: 15 },
            { x: 3, y: -9, delay: 16 }
          ]
        },
        {
          txt: "로",
          x: -80,
          y: -59,
          images: [
            { x: 0, y: -20, delay: 17 },
            { x: -10, y: -15, delay: 18 },
            { x: -29, y: 7, delay: 19 },
            { x: -53, y: 14, delay: 20 }
          ]
        },
        {
          txt: "딩",
          x: -57,
          y: -94,
          images: [
            { x: 0, y: 0, delay: 21 },
            { x: -15, y: -15, delay: 22 },
            { x: -8, y: 0, delay: 23 },
            { x: -23, y: 21, delay: 24 },
            { x: -29, y: 16, delay: 25 }
          ]
        },
        {
          txt: "중",
          x: 9,
          y: -122,
          images: [
            { x: 0, y: 0, delay: 26 },
            { x: -7, y: 0, delay: 27 },
            { x: -36, y: 19, delay: 28 },
            { x: -39, y: 8, delay: 29 },
            { x: -61, y: 31, delay: 30 },
            { x: -67, y: 24, delay: 31 }
          ]
        },
        {
          txt: "입",
          x: 43,
          y: -146,
          images: [
            { x: 0, y: -10, delay: 32 },
            { x: -6, y: -16, delay: 33 },
            { x: -3, y: -2, delay: 34 },
            { x: -22, y: 11, delay: 35 },
            { x: -33, y: 5, delay: 36 }
          ]
        },
        {
          txt: "니",
          x: 56,
          y: -180,
          images: [
            { x: 0, y: 0, delay: 37 },
            { x: 0, y: 2, delay: 38 }
          ]
        },
        {
          txt: "다",
          x: 89,
          y: -214,
          images: [
            { x: 15, y: 0, delay: 39 },
            { x: -2, y: -17, delay: 40 },
            { x: 3, y: 2, delay: 41 },
            { x: -3, y: -8, delay: 42 }
          ]
        },
        {
          txt: "점",
          x: 124,
          y: -211,
          images: [
            { x: 6, y: -18, delay: 52 },
            { x: 6, y: -18, delay: 62 },
            { x: 4, y: -18, delay: 72 }
          ]
        }
      ],
      result: {
        e: 0,
        i: 0,
        s: 0,
        n: 0,
        t: 0,
        f: 0,
        j: 0,
        p: 0
      },
      quiz: [
        {
          q: "교실 앞에서 자기소개 할 때 나는",
          icon: {
            x: -135,
            y: -83
          },
          a: [
            {
              txt: "존재감을 확실히 드러내며 친해지자고 한다",
              type: "e"
            },
            {
              txt:
                "주목받고 싶지 않아 간단한 정보만<br/>말하고 자리로 돌아간다",
              type: "i"
            }
          ]
        },
        {
          q: "옆 줄 학생이 나를 빤히 쳐다볼 때 나는",
          icon: {
            x: 62,
            y: -86
          },
          a: [
            {
              txt:
                "‘나한테 관심있나... 설마 한눈에 반한 거 아니야?’ 온갖 상상을 한다",
              type: "n"
            },
            {
              txt: "‘내 얼굴에 뭐가 묻었나?’ 거울을 본다",
              type: "s"
            }
          ]
        },
        {
          q: "다가오는 친구가 생겼다. 어느 쪽이 반가울까?",
          icon: {
            x: -43,
            y: -87
          },
          a: [
            {
              txt: `“처음이라 많이 낯설지? 반갑다!” 다정하게 다가와주는 친구`,
              type: "f"
            },
            {
              txt: `“학교에 대해 궁금한 거 있어?” 학교시설, 규칙을 알려주는 친구`,
              type: "t"
            }
          ]
        },
        {
          q: "사물함을 배정 받았을 때 나는",
          icon: {
            x: -143,
            y: -66
          },
          a: [
            {
              txt: "미리 준비한 교과서, 공책, 학용품을 차곡차곡 채워 넣는다",
              type: "j"
            },
            {
              txt: "교과서만 대충 올려놓는다",
              type: "p"
            }
          ]
        },
        {
          q: "문학 수업시간, 선생님이 갑자기 내 이름을 부를 때 나는",
          icon: {
            x: -84,
            y: -86
          },
          a: [
            {
              txt:
                "‘설마 아까 교무실에서 선생님이랑 부딪히고 그냥 간 것 때문에...? 화장실이 급해서 그랬다고 얘기할까...? 차마 매점 초코빵이 몇개 안남아서 뛰어나갔다곤 할 수 없는데...’ 라며 조마조마한다",
              type: "n"
            },
            {
              txt: "‘뭐지? 깜빡 졸았나?‘ 생각한다",
              type: "s"
            }
          ]
        },
        {
          q: "점심시간, 친구들과 급식을 먹을 때 나는",
          icon: {
            x: 0,
            y: -100
          },
          a: [
            {
              txt:
                "친구들에게 오늘 나온 소시지 야채볶음이 맛있다며 신나게 밥을 먹는다",
              type: "e"
            },
            {
              txt: "친구들의 이야기를 들으며 조용히 밥을 먹는다",
              type: "i"
            }
          ]
        },
        {
          q: "매점에서 내가 추천해준 과자가 친구가 별로라고 할 때 나는",
          icon: {
            x: -120,
            y: -100
          },
          a: [
            {
              txt: "“아 진짜...? 미안해ᅲᅲ“ 나 때문이라고 생각하고 속상해한다",
              type: "f"
            },
            {
              txt: `“너무 달아?” 어떤 부분이 별로였는지 물어본 후 다른 과자를 추천해준다`,
              type: "t"
            }
          ]
        },
        {
          q: "잠깐 눈을 붙이다 다음 체육수업에 늦었을 때 나는",
          icon: {
            x: 90,
            y: -100
          },
          a: [
            {
              txt:
                "‘늦었다고 운동장 돌라고 하는거 아니야? 뭐라고 둘러대지? 체육복 찾는다고? 학주쌤이 불러서? 아아아아 어떡하지’ 하며 초조해하다가 오히려 더 늦어버린다",
              type: "n"
            },
            {
              txt:
                "‘언제 시간이 이렇게 지났지? 일단 나가자’ 현실파악을 빠르게 하고 얼른 체육복을 챙겨 나간다",
              type: "s"
            }
          ]
        },
        {
          q: "짝꿍이 수학 성적이 떨어졌다며 우울해 할 때 나는",
          icon: {
            x: -120,
            y: -100
          },
          a: [
            {
              txt: `“에이ᅲᅲ 낙담하지마... 다들 어려웠다고 하더라!“라고 말한다`,
              type: "f"
            },
            {
              txt: `“무슨 문제 틀렸는데? 기말고사 더 잘 보면 되지”라고 말한다`,
              type: "t"
            }
          ]
        },
        {
          q: "쉬는 시간에 나는",
          icon: {
            x: -120,
            y: -100
          },
          a: [
            {
              txt: "다른 친구들과 여기저기 돌아다닌다",
              type: "e"
            },
            {
              txt: "혼자 음악을 들으며 쉰다",
              type: "i"
            }
          ]
        },
        {
          q: "집 가는 길, 독서실에 가려는데 친구가 놀자고 붙잡을 때 나는",
          icon: {
            x: -120,
            y: -100
          },
          a: [
            {
              txt: "오늘은 할 게 있으니 다음에 놀자고 한다",
              type: "j"
            },
            {
              txt: "공부는 내일부터! 친구와 놀러간다",
              type: "p"
            }
          ]
        },
        {
          q: "좋아하는 친구에게 고백하려고 할 때 나는",
          icon: {
            x: -120,
            y: -100
          },
          a: [
            {
              txt: `‘언제, 어디서, 어떻게 고백하지...?’ 고민한다`,
              type: "j"
            },
            {
              txt: `‘분위기 봐서 고백 해야겠다. 일단 연락부터 해야지 ᄒᄒ‘ 생각한다`,
              type: "p"
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.quizIndex = 0;
    this.show = true;
    this.result = { e: 0, i: 0, s: 0, n: 0, t: 0, f: 0, j: 0, p: 0 };
    document.body.style.backgroundColor = "#004f32";
  },
  methods: {
    getScore(type) {
      this.lastType.push(type);
      this.result[type] += 1;
      this.quizIndex += 1;
      this.quizTransition = false;
      setTimeout(() => {
        this.quizTransition = true;
      }, 200);
      if (this.quizIndex >= this.quiz.length) {
        this.showLoading = true;
        const increment = firebase.firestore.FieldValue.increment(1);
        const docRef = this.$firestore.collection("user").doc("snackgotest");
        docRef.update({ finishCount: increment });
        setTimeout(() => {
          this.$router.push({
            name: "Result",
            params: {
              id: this.getResult()
            }
          });
        }, 72 * 60 + 300 + 500);
      }
    },
    prevQuiz() {
      if (this.lastType.length === 0) {
        this.$router.push({
          name: "Home"
        });
      }
      this.result[this.lastType.pop()] -= 1;
      this.quizIndex -= 1;
    },
    getResult() {
      const result = [
        this.result.e > this.result.i ? "e" : "i",
        this.result.s > this.result.n ? "s" : "n",
        this.result.f > this.result.t ? "f" : "t",
        this.result.j > this.result.p ? "j" : "p"
      ];
      return result.join("");
    }
  }
};
</script>
<style lang="scss" scoped>
header.quiz-title {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  margin-top: 40px;
  .icon {
    position: absolute;
    width: initial;
  }
}
.each-text {
  display: inline;
  & > {
    img {
      /* transition: 1s; */
      opacity: 0;
      animation-fill-mode: forwards;
      animation-name: show;
      animation-duration: 0.3s;
      animation-timing-function: ease-in;
      display: inline-block;
      width: initial;
    }
  }
}
.pulled-up {
  transform: translateY(-60px);
}
</style>